<template>
  <section class="booking--detail-survey" v-if="tourInfo">
    <div class="left--part">
      <div>
        Jadwal Survei
        <div>
          {{ tourInfo.date }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'accepted-tour-section',
  props: {
    tourInfo: {
      required: true,
    },
  },
};
</script>

<style scoped></style>
